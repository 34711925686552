import React from "react";
import { QrReader } from "react-qr-reader";
import { useSearchParams } from "react-router-dom";

export default function QRCodeScanner(props) {
  const [walletConnectURI, setWalletConnectURI] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [devices, setDevices] = React.useState(null);
  const [hideCamera, setHideCamera] = React.useState(true);
  const [videoConstraints, setVideoConstraints] = React.useState(null);
  const debug = searchParams.get("debug");

  const { onClose, onScanned } = props;
  const handleInput = async (e) => {
    setWalletConnectURI(e.target.value);
  };

  React.useEffect(() => {
    if (walletConnectURI === "") {
      return;
    }
    if (walletConnectURI.startsWith("wc:")) {
      //send to parent view
      onScanned(walletConnectURI);
    }
  }, [walletConnectURI]);

  const getDevices = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
    return await navigator.mediaDevices.enumerateDevices();
  };
  React.useEffect(() => {
    getDevices().then((available) => {
      setDevices(available);
    });
  }, []);

  React.useEffect(() => {
    if (devices !== null) {
      const first = devices.filter((d) => {
        return d.label.toLowerCase().includes("back");
      });
      if (first.length > 0) {
        setVideoConstraints({ deviceId: first[0].deviceId });
        setHideCamera(false);
      } else {
        setVideoConstraints({ deviceId: "", facingMode: "environment" });
        setHideCamera(false);
      }
    }
  }, [devices]);

  const onChangeVideoInput = (e) => {
    setVideoConstraints({ deviceId: e.target.value, aspectRatio: 1.0 });
    setHideCamera(true);
    setTimeout(() => {
      setHideCamera(false);
    }, 500);
  };
  return (
    <div className=" overflow-hidden transition ease-in-out duration-300 opacity-100 z-50 bg-white border absolute top-[150px] bottom-0 left-0 w-full rounded-t-3xl flex flex-col items-center justify-center drop-shadow-[0_-9px_17px_rgba(0,0,0,0.25)]">
      <div className=" flex flex-col items-center w-full h-full ">
        <div className="bg-black w-full overflow-hidden">
          {videoConstraints !== null && hideCamera === false ? (
            <QrReader
              constraints={videoConstraints}
              onResult={(result, error) => {
                if (!!result) {
                  if (result?.text.startsWith("wc:")) {
                    setWalletConnectURI(result?.text);
                  }
                }
              }}
            />
          ) : null}
        </div>
        <div className="px-[12px] mt-[20px] w-full text-center">
          <div className="flex items-center justify-center mb-3">
            <select
              value={videoConstraints ? videoConstraints.deviceId : ""}
              className="w-full border rounded"
              onChange={onChangeVideoInput}
            >
              {devices &&
                devices
                  .filter((d) => {
                    return d.kind == "videoinput";
                  })
                  .map((d) => (
                    <option key={d.deviceId} value={d.deviceId}>
                      {d.label}
                    </option>
                  ))}
            </select>
          </div>
          <p className="mb-3 font-light">
            Scan QR code on the Gashapon Machine to connect
          </p>
        </div>
      </div>

      <div className="absolute bottom-0 left-0 bg-white flex-none w-full text-center p-3">
        <button
          onClick={(e) => onClose()}
          className="bg-black w-full py-2 font-semibold text-white uppercase"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
