import React from "react";
import EventContext from "../../contexts/EventContext";

export default function Header(props) {
  const { event } = React.useContext(EventContext);
  return (
    <div className="w-full">
      <div className="flex flex-col items-center mx-auto">
        <svg
          className="h-10 mx-auto"
          viewBox="0 0 65 26"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="#003">
            <path d="m35.8 9a4.2 4.2 0 0 1 1.3 3.3v7.4h-1.9v-6.8a3.8 3.8 0 0 0 -.7-2.6 2.9 2.9 0 0 0 -2.1-.8 3.3 3.3 0 0 0 -1.7.4 3.5 3.5 0 0 0 -1.4 1.3 5.2 5.2 0 0 0 -.5 2.3v6.2h-1.8v-11.2l1.7.7v.7a3.3 3.3 0 0 1 1.6-1.5 5.6 5.6 0 0 1 2.3-.5 4.8 4.8 0 0 1 3.2 1.1z" />
            <path d="m50.2 14.6h-8.7a3.8 3.8 0 0 0 1.2 2.7 3.4 3.4 0 0 0 2.6 1 6.5 6.5 0 0 0 2.1-.4 2.3 2.3 0 0 0 1.1-1.3l1.6.8a5.8 5.8 0 0 1 -1.9 1.9 6 6 0 0 1 -2.9.6 5.3 5.3 0 0 1 -4.1-1.6 6 6 0 0 1 -1.5-4.4 6.6 6.6 0 0 1 1.4-4.4 5.1 5.1 0 0 1 4.1-1.6 4.7 4.7 0 0 1 2.7.7 4.4 4.4 0 0 1 1.8 2 6 6 0 0 1 .6 2.7 5.7 5.7 0 0 1 -.1 1.3zm-7.5-4.2a4.9 4.9 0 0 0 -1.2 2.6h7a4.1 4.1 0 0 0 -1-2.6 3.2 3.2 0 0 0 -2.3-.9 3.3 3.3 0 0 0 -2.5.9z" />
            <path d="m62.6 9.5a6.6 6.6 0 0 1 1.4 4.4 6.4 6.4 0 0 1 -1.4 4.4 5.3 5.3 0 0 1 -4.1 1.6 5 5 0 0 1 -4.1-1.6 6 6 0 0 1 -1.5-4.4 6.2 6.2 0 0 1 1.5-4.4 5 5 0 0 1 4.1-1.6 5.3 5.3 0 0 1 4.1 1.6zm-6.9 1.2a4.6 4.6 0 0 0 -1 3.2 4.4 4.4 0 0 0 1 3.2 3.5 3.5 0 0 0 2.8 1.2 3.4 3.4 0 0 0 2.7-1.2 4.4 4.4 0 0 0 1-3.2 4.6 4.6 0 0 0 -1-3.2 3.4 3.4 0 0 0 -2.7-1.2 3.5 3.5 0 0 0 -2.8 1.2z" />
          </g>
          <path
            d="m1.9 5.7v16l10.4 3.8v-16.2l11.3-4.2-10.3-3.6z"
            fill="#00e599"
          />
          <path d="m13.1 9.9v8.6l10.5 3.8v-16.3z" fill="#00af92" />
        </svg>
        <p className="tracking-widest font-black font-thin mt-[10px]">
          {event.name}
        </p>
      </div>
    </div>
  );
}
