import React from "react";
import Header from "../Components/Header";
import QRCode from "react-qr-code";
import { useWalletConnect } from "@cityofzion/wallet-connect-sdk-react";
import { Buffer } from "buffer";
import BlueChest from "./../../assets/blue_chest.png";
import Ball from "./../../assets/ball.svg";
import BallBox from "./../../assets/ball_box.svg";
import ConnectedBlueChest from "./../../assets/connected_blue_chest.png";
import GreenChest from "./../../assets/green_chest.png";
import ConnectedGreenChest from "./../../assets/connected_green_chest.png";
import GoldChest from "./../../assets/gold_chest.png";
import ConnectedGoldChest from "./../../assets/connected_gold_chest.png";
import BluePuppet from "./../../assets/puppet_blue.png";
import GreenPuppet from "./../../assets/puppet_green.png";
import GoldPuppet from "./../../assets/puppet_gold.png";
import BlacksmithPuppet from "./../../assets/puppet_blacksmith.png";
import ConnectedBlacksmithChest from "./../../assets/connected_blacksmith_chest.png";
import BlacksmithChest from "./../../assets/blacksmith_chest.png";
import { useSearchParams } from "react-router-dom";
// polyfill Buffer for client
if (!window.Buffer) {
  window.Buffer = Buffer;
}

export default function ChestApp(props) {
  const TIMEOUT_IN_SECONDS = 120;
  const { chestMetadata } = props;

  var wcSdk = useWalletConnect();
  const [walletConnectURI, setWalletConnectURI] = React.useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const debug = searchParams.get("debug");
  const [isConnected, setIsConnected] = React.useState("false");
  const [timeoutSecond, setTimeoutSecond] = React.useState(TIMEOUT_IN_SECONDS);
  const [account, setAccount] = React.useState(null);
  const [connecting, setConnecting] = React.useState(false);

  const NEW_URI_TIMEOUT_IN_SECONDS = searchParams.get("timeout") ?? 20; //20s to get new URI if not connected

  const [newURITimeoutSecond, setNewURITimeoutSecond] = React.useState(
    NEW_URI_TIMEOUT_IN_SECONDS
  );
  const [newURIInterval, setNewURIInterval] = React.useState(null);

  const chestConfigurations = {
    green: {
      chestColor: "text-primary",
      chestImage: GreenChest,
      connectedChestImage: ConnectedGreenChest,
      puppetImage: GreenPuppet,
    },
    blue: {
      chestColor: "text-blue-600",
      chestImage: BlueChest,
      connectedChestImage: ConnectedBlueChest,
      puppetImage: BluePuppet,
    },
    gold: {
      chestColor: "text-yellow-400",
      chestImage: GoldChest,
      connectedChestImage: ConnectedGoldChest,
      puppetImage: GoldPuppet,
    },
    blacksmith: {
      chestColor: "text-black",
      chestImage: BlacksmithChest,
      connectedChestImage: ConnectedBlacksmithChest,
      puppetImage: BlacksmithPuppet,
    },
  };

  React.useEffect(() => {
    if (
      wcSdk.signClient !== undefined &&
      wcSdk.isConnected() === false &&
      walletConnectURI == null &&
      connecting == false
    ) {
      console.log("connecting...");
      setConnecting(true);
      clearInterval(newURIInterval);
      setNewURITimeoutSecond(NEW_URI_TIMEOUT_IN_SECONDS);
      connectWallet();
    }
  }, [wcSdk, walletConnectURI, connecting]);

  const connectWallet = async () => {
    if (
      wcSdk.signClient !== undefined &&
      wcSdk.isConnected() === false &&
      walletConnectURI == null &&
      connecting == false
    ) {
      await wcSdk.connect("neo3:mainnet", (uri) => {
        console.log("uri", uri);
        setConnecting(false);
        setWalletConnectURI(uri);

        //generate new URI every 20s to prevent stale connection
        if (newURIInterval) {
          clearInterval(newURIInterval);
        }
        const interval = setInterval(() => {
          setNewURITimeoutSecond(
            (newURITimeoutSecond) => newURITimeoutSecond - 1
          );
        }, 1000);
        setNewURIInterval(interval);
        return () => clearInterval(interval);
      });
    }
  };

  React.useEffect(() => {
    const isConnected = wcSdk.isConnected();
    if (isConnected == true) {
      setIsConnected(true);
      setAccount(wcSdk.getAccountAddress());
    } else {
      setIsConnected(false);
    }
  }, [wcSdk.isConnected()]);

  React.useEffect(() => {
    console.log("wcSdk.session", wcSdk.session);
    if (wcSdk.session !== undefined) {
      setIsConnected(true);

      //stop interval to get new URI when connected and reset the second
      clearInterval(newURIInterval);
      setNewURITimeoutSecond(NEW_URI_TIMEOUT_IN_SECONDS);
      setWalletConnectURI(null);
      //after connected, if there is no action in 120s then disconnect
      const interval = setInterval(() => {
        setTimeoutSecond((timeoutSecond) => timeoutSecond - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      clearInterval(newURIInterval);
      setNewURITimeoutSecond(NEW_URI_TIMEOUT_IN_SECONDS);
      setWalletConnectURI(null);
    }
  }, [wcSdk.session]);

  React.useEffect(() => {
    if (timeoutSecond < 0) {
      disconnect();
    }
  }, [timeoutSecond]);

  React.useEffect(() => {
    if (newURITimeoutSecond < 0) {
      if (wcSdk.session === undefined) {
        setNewURITimeoutSecond(NEW_URI_TIMEOUT_IN_SECONDS);
        setWalletConnectURI(null);
        setIsConnected(false);
        connectWallet();
      }
    }
  }, [newURITimeoutSecond]);

  const disconnect = async () => {
    if (wcSdk.session === undefined) {
      return;
    }

    await wcSdk.disconnect();
    setTimeoutSecond(TIMEOUT_IN_SECONDS);
    setNewURITimeoutSecond(NEW_URI_TIMEOUT_IN_SECONDS);
    setWalletConnectURI(null);
    setIsConnected(false);
  };

  const chestColor = () => {
    var config = chestConfigurations[chestMetadata.chestColor];
    if (config) {
      return config.chestColor;
    }
    return "text-gray-600";
  };

  const chestImage = () => {
    var config = chestConfigurations[chestMetadata.chestColor];
    if (config) {
      return config.chestImage;
    }
    return GoldChest;
  };

  const connectedChestImage = () => {
    var config = chestConfigurations[chestMetadata.chestColor];
    if (config) {
      return config.connectedChestImage;
    }
    return ConnectedGoldChest;
  };

  const puppetImage = () => {
    var config = chestConfigurations[chestMetadata.chestColor];
    if (config) {
      return config.puppetImage;
    }
    return GoldPuppet;
  };

  const resetConnection = async () => {
    if (wcSdk.session === undefined) {
      await wcSdk.disconnect();
      setTimeoutSecond(TIMEOUT_IN_SECONDS);
      setNewURITimeoutSecond(NEW_URI_TIMEOUT_IN_SECONDS);
      setWalletConnectURI(null);
      setIsConnected(false);
      connectWallet();
    } else {
      disconnect();
    }
  };

  return (
    <div>
      <div className="p-3 container mx-auto relative flex flex-col items-center justify-center h-full ">
        <div className="mt-0">
          <Header></Header>
        </div>

        <div className="h-full tex-center flex flex-col items-center justify-center p-6">
          {wcSdk.isConnected() === false ? (
            <div className="flex flex-col items-center justify-center">
              <div className=" w-70 h-70 bg-white rounded-lg shadow-lg p-6 flex items-center justify-center">
                {walletConnectURI ? (
                  <QRCode size={250} value={walletConnectURI}></QRCode>
                ) : (
                  <div>
                    {wcSdk.loadingSession ? (
                      <p className="font-bold">Loading</p>
                    ) : !wcSdk.session ? (
                      <button
                        className="bg-primary p-3"
                        onClick={connectWallet}
                      >
                        Start {chestMetadata.chestColor} chest
                      </button>
                    ) : null}
                  </div>
                )}
              </div>
              <p className="uppercase font-thin py-6">GASHAPON MACHINE</p>

              <div>
                <button
                  onClick={(e) => {
                    resetConnection();
                  }}
                  className="flex items-center justify-center gap-[10px]"
                >
                  <img className="h-10" src={Ball} />
                  <img className="h-10 rotate-45" src={Ball} />
                  <img className="h-10 rotate-90" src={Ball} />
                </button>
              </div>
              <p className="text-center font-thin p-3">
                Scan this QR code to connect the Gashapon Machine
              </p>
              <p className="text-xs text-gray-500">{newURITimeoutSecond}</p>
              {debug === "1" ? (
                <div>
                  <p className="bg-gray-50 p-2 rounded">{walletConnectURI}</p>
                  <p className="text-xs text-gray-500">{timeoutSecond}</p>
                </div>
              ) : null}
            </div>
          ) : null}

          {wcSdk.session ? (
            <div className="flex flex-col items-center">
              <img src={BallBox} className="w-[200px]" />

              <p className="font-medium mb-3 mt-[20px]">Your’re Connected!</p>

              {account !== null ? (
                // var [namespace, reference, address] = account.split(":");
                <div className="flex flex-col items-center justify-center">
                  <p className="bg-slate-200 px-3 py-1 rounded-full text-sm font-medium">
                    {account}
                  </p>
                  <p className="text-sm font-medium mt-[20px]">
                    Send transaction to play the Gashapon Machine
                  </p>
                  <p className="text-xs text-gray-500">{timeoutSecond}</p>
                  <button
                    className="mt-6 text-xs uppercase font-bold text-red-600 bg-red-200 p-2"
                    onClick={(e) => disconnect()}
                  >
                    Disconnect
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
