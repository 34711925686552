import React from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import AccountContext from "../../contexts/AccountContext";
import Header from "../Components/Header";
import LoadingGIF from "./../../assets/icons8-rhombus-loader.gif";
import BallBox from "./../../assets/ball_box.svg";
import ConnectedIcon from "./../../assets/connected_icon.svg";
import Loading from "../Components/Loading";
import { Chest, types } from "@cityofzion/props";
import Neon, { rpc, api, sc } from "@cityofzion/neon-js";
import * as ChestProps from "@cityofzion/props";
import ConnectedBlacksmithChest from "./../../assets/connected_blacksmith_chest.png";
import { useWalletConnect } from "../../contexts/WalletConnectContext";

export default function Connected(props) {
  const navigate = useNavigate();

  const {
    account,
    chestKey,
    puppetTokenId,
    goldPuppetTokenId,
    isEligibleForGoldChest,
  } = React.useContext(AccountContext);
  // const { wcClient, isConnected, chestMetadata, session, proposal } = React.useContext(WalletConnectContext)
  const [connecting, setConnecting] = React.useState(false);
  const [openingChest, setOpeningChest] = React.useState(false);
  const walletConnectCtx = useWalletConnect();

  const isConnected = walletConnectCtx.sessions.length > 0;

  const firstSession =
    isConnected == true &&
    walletConnectCtx.sessions &&
    walletConnectCtx.sessions[0];
  const chestMetadata = firstSession && firstSession.peer.metadata;

  const chestConfigurations = {
    green: {
      chestColor: "text-primary",
      chestKeyColor: "text-primary",
    },
    blue: {
      chestColor: "text-blue-600",
      chestKeyColor: "text-blue-600",
    },
    gold: {
      chestColor: "text-yellow-400",
      chestKeyColor: "text-yellow-400",
    },
    blacksmith: {
      chestColor: "text-black",
      chestKeyColor: "text-black",
    },
  };

  React.useEffect(() => {
    if (isConnected == false) {
      navigate("/", { replace: true });
    } else {
    }
  }, [isConnected]);

  const chestColor = () => {
    if (chestMetadata === null || chestMetadata.chestColor === "") {
      return "";
    }

    var config = chestConfigurations[chestMetadata.chestColor];
    if (config) {
      return config.chestColor;
    }
    return "text-gray-600";
  };

  const chestKeyColor = () => {
    if (chestKey === null) {
      return "";
    }
    var config = chestConfigurations[chestMetadata.chestColor];
    if (config) {
      return config.chestKey;
    }
    return "text-gray-600";
  };

  const onOpenChest = async () => {
    const fromAccount = Neon.create.account(account._WIF);
    // const node = Neon.create.rpcClient("https://testnet1.neo.coz.io:443");
    const node = Neon.create.rpcClient("https://mainnet1.neo.coz.io:443");

    const params = [sc.ContractParam.hash160(fromAccount.address)];
    setOpeningChest(true);
    try {
      const res = await ChestProps.api.NeoInterface.publishInvoke(
        node.url,
        Neon.CONST.MAGIC_NUMBER.MainNet,
        // 894710606,
        "0x1a79316efa784177bde4de0f3690cdd2488ed009",
        "twist",
        params,
        fromAccount
      );
      const fetchInitData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          account: fromAccount.address,
          tx_id: res,
        }),
      };
      fetch("https://allweb.ngd.network:8443/verify", fetchInitData)
        .then((res) => res.json())
        .then((result) => {
          if (result.result === true && result.gacha.recode === 0) {
            setConnecting(false);
            setOpeningChest(false);

            navigate({
              pathname: "/result",
              replace: true,
            });
            //disconnect as soon as we get the result
            // wcClient.disconnect({ topic: session.topic, reason: { code: 5900, message: 'USER_DISCONNECTED' }, })
            walletConnectCtx.disconnect(firstSession.topic);
          } else {
            console.log(result?.gacha?.errmsg ?? "Open Gashapon Machine Error");
          }
        })
        .catch((error) => {
          walletConnectCtx.disconnect(firstSession.topic);
          setOpeningChest(false);
          navigate("/", { replace: true });
        });
    } catch (e) {
      walletConnectCtx.disconnect(firstSession.topic);
      setOpeningChest(false);
      navigate("/", { replace: true });
    }
  };

  const openGoldChest = async () => {
    const targetNetwork = types.NetworkOption.MainNet;
    const chestId = chestMetadata.id.toString();
    const puppetId = goldPuppetTokenId.toString(); //use gold puppet token id
    console.log("chestId", chestId, typeof chestId);
    console.log("puppetId", puppetId, typeof puppetId);

    const userWallet = Neon.create.account(account._WIF); //make sure we new account here before sending it in
    console.log("userWallet", userWallet);
    const chest = await new Chest({
      network: targetNetwork,
    });
    await chest.init();

    const isEligible = await chest.isPuppetEligible(
      chestId.toString(),
      puppetId.toString()
    );

    if (isEligible === true) {
      try {
        const toOpenchestWallet = Neon.create.account(account._WIF.toString());
        console.log("opening chest", toOpenchestWallet);
        const res = await chest.lootChestWithPuppetVerified(
          chestId,
          puppetId,
          toOpenchestWallet
        );
        console.log("lootChestWithPuppetVerified ", res);

        setConnecting(false);
        setOpeningChest(false);

        navigate({
          pathname: "/goldresult",
          replace: true,
        });
        //disconnect as soon as we get the result
        // wcClient.disconnect({ topic: session.topic, reason: { code: 5900, message: 'USER_DISCONNECTED' }, })
        walletConnectCtx.disconnect(firstSession.topic);
      } catch (error) {
        // wcClient.disconnect({ topic: session.topic, reason: { code: 5900, message: 'USER_DISCONNECTED' }, })
        walletConnectCtx.disconnect(firstSession.topic);
        setOpeningChest(false);
        navigate("/", { replace: true });
        return;
      }
    } else {
      // wcClient.disconnect({ topic: session.topic })
      walletConnectCtx.disconnect(firstSession.topic);
      setOpeningChest(false);
      navigate("/", { replace: true });
    }
  };

  const openChest = async () => {
    const targetNetwork = types.NetworkOption.MainNet;
    const chestId = chestMetadata.id.toString();
    const puppetId = puppetTokenId.toString();
    console.log("chestId", chestId, typeof chestId);
    console.log("puppetId", puppetId, typeof puppetId);

    const userWallet = Neon.create.account(account._WIF); //make sure we new account here before sending it in
    console.log("userWallet", userWallet);
    const chest = await new Chest({
      network: targetNetwork,
    });
    await chest.init();

    const isEligible = await chest.isPuppetEligible(
      chestId.toString(),
      puppetId.toString()
    );

    if (isEligible === true) {
      try {
        const toOpenchestWallet = Neon.create.account(account._WIF.toString());
        console.log("opening chest", toOpenchestWallet);

        const res = await chest.lootChestWithPuppetVerified(
          chestMetadata.id,
          puppetTokenId,
          toOpenchestWallet
        );
        console.log("lootChestWithPuppetVerified ", res);

        const protocol = res[0].type === "NEP-17" ? "nep17" : "nep11";
        const contract = "0x" + res[0].scriptHash.toString();
        const tokenId = res[0].tokenId;
        const quantity = res[0].quantity;

        setConnecting(false);
        setOpeningChest(false);

        navigate({
          pathname: "/result",
          replace: true,
          search: createSearchParams({
            chest: chestMetadata.chestColor,
            protocol: protocol,
            contract: contract,
            token_id: tokenId,
            quantity: quantity,
          }).toString(),
        });
        //disconnect as soon as we get the result
        walletConnectCtx.disconnect(firstSession.topic);
      } catch (error) {
        console.log("error", error);
        walletConnectCtx.disconnect(firstSession.topic);
        setOpeningChest(false);
        navigate("/", { replace: true });
        return;
      }
    } else {
      walletConnectCtx.disconnect(firstSession.topic);
      setOpeningChest(false);
      navigate("/", { replace: true });
      return;
    }
  };

  const disconnect = () => {
    walletConnectCtx.disconnect(firstSession.topic);
    props.onDisconnect();
  };

  return (
    <div className=" absolute inset-0 text-secondary">
      {connecting === true ? <Loading></Loading> : null}
      <div className="p-3 container mx-auto relative flex flex-col h-full ">
        {isConnected === true ? (
          <button onClick={(e) => disconnect()}>
            <div className={`flex items-center ${chestColor()}`}>
              <svg
                className="fill-current mr-1"
                width="24"
                height="12"
                viewBox="0 0 24 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M21.72 6C21.72 3.948 20.052 2.28 18 2.28H13.2V0H18C21.312 0 24 2.688 24 6C24 9.312 21.312 12 18 12H13.2V9.72H18C20.052 9.72 21.72 8.052 21.72 6ZM16.8 7.2H7.2V4.8H16.8V7.2ZM6 0H10.8V2.28H6C3.948 2.28 2.28 3.948 2.28 6C2.28 8.052 3.948 9.72 6 9.72H10.8V12H6C2.688 12 0 9.312 0 6C0 2.688 2.688 0 6 0Z" />
              </svg>

              <span className="font-semibold text-sm uppercase">CONNECTED</span>
            </div>
          </button>
        ) : null}
        <div className="mt-6">
          <Header></Header>
        </div>
        <div className="h-full flex flex-col items-center mt-[100px]">
          {account !== null && openingChest === true ? (
            <div className="flex flex-col items-center justify-center">
              <div className="mx-auto mb-3">
                <img className="h-12" src={LoadingGIF} />
              </div>
              <p className="font-bold text-lg">Opening The GASHAPON MACHINE</p>
            </div>
          ) : null}

          {account !== null && openingChest == false ? (
            <div>
              <div className="flex flex-col items-center">
                <img src={BallBox} className="w-[200px]" />
                <img src={ConnectedIcon} className="w-[50px] mt-[20px]" />
              </div>
              <p className="font-semibold uppercase text-center mt-[20px]">
                YOU ARE CONNECTED TO THE GASHAPON MACHINE
              </p>
            </div>
          ) : null}
        </div>

        <div className="mt-auto p-3 w-full">
          {account !== null && isConnected === true && openingChest == false ? (
            <div>
              <button
                onClick={onOpenChest}
                disabled={connecting}
                className="disabled:bg-opacity-50 bg-primary w-full py-2 font-semibold text-white uppercase"
              >
                Play
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
