import React from "react";

import AccountContext from "../../contexts/AccountContext";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Header from "../Components/Header";
import Global from "../../Global";
import OpenBox from "./../../assets/open_box.svg";

import { Puppet, types } from "@cityofzion/props";
import QRCode from "react-qr-code";

export default function Result(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { account, chestKey, setChestKey, setGoldPuppetTokenId } =
    React.useContext(AccountContext);

  const navigate = useNavigate();
  const location = useLocation();

  const protocol = searchParams.get("protocol");
  const contract = searchParams.get("contract");
  const tokenId = searchParams.get("token_id");
  const quantity = searchParams.get("quantity");

  const resultFromChest = searchParams.get("chest");

  const [loading, setLoading] = React.useState(false);
  const [nep11Response, setNep11Response] = React.useState(null);

  const [assets, setAssets] = React.useState([]);
  const [nep11Assets, setNep11Assets] = React.useState(null);

  const [isGoldKey, setIsGoldKey] = React.useState(false);

  const puppetMainnetContract = "0x76a8f8a7a901b29a33013b469949f4b08db15756";
  const puppetTestnetContract = "0x97857c01d64f846b5fe2eca2d09d2d73928b3f43";
  const SupportedAssets = Global.Assets;

  const viewWallet = () => {
    navigate("/wallet", { replace: true });
  };

  const viewWalletAndOpenScan = () => {
    navigate("/wallet?open=scan", { replace: true });
  };

  const fetchNEP11Data = async (contract, tokenId) => {
    //IF we found that the contract is puppet one we can use props sdk to fetch token detail by
    if (contract === puppetMainnetContract) {
      const targetNetwork = types.NetworkOption.MainNet;
      const puppet = await new Puppet({
        network: targetNetwork,
      });
      await puppet.init();
      const result = await puppet.getPuppetJSON(tokenId);
      let isGoldKey = result.epochId === 5; //gold pupet
      if (isGoldKey == true) {
        //if user got a gold key then we set the main key in AccountContext to be "gold"
        //so we can use it in the next screen
        setGoldPuppetTokenId(tokenId);
        setIsGoldKey(true);
      }

      return;
    }

    //fetch another result
    //e.g. https://api.ghostmarket.io/api/v1/metadata?chain=n3&contract=0x76a8f8a7a901b29a33013b469949f4b08db15756&token_id=825766707
    const url = `https://api.ghostmarket.io/api/v1/metadata?chain=n3&contract=${contract}&token_id=${tokenId}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setNep11Response(data);
        console.log(data);
        setLoading(false);
      });
  };

  const loadAssets = async () => {
    const fetchData = (url) => fetch(url).then((r) => r.json()); // 1
    const nep11AssetsUrl = `https://api.ghostmarket.io/api/v1/assets?chain=n3&owner=${account.address}`;
    const balancesUrl = `https://dora.coz.io/api/v1/neo3/mainnet/balance/${account.address}`;

    const [nep11Balances, balances] = await Promise.all([
      fetchData(nep11AssetsUrl),
      fetchData(balancesUrl),
    ]);
    console.log(balances);
    setNep11Assets(nep11Balances);
    setAssets(balances);
    setLoading(false);
  };

  const fetchNEP17Data = (contract) => {
    //we don't need to fetch anything here because we are showing the static result
  };

  React.useEffect(() => {
    if (protocol === "nep11") {
      setLoading(true);
      fetchNEP11Data(contract, tokenId);
    } else if (protocol === "nep17") {
      fetchNEP17Data(contract);
    }
  }, [contract, protocol, tokenId]);

  // React.useEffect(() => {
  //     if (account !== null) {
  //         loadAssets()
  //     }

  // }, [account])

  return (
    <div className="h-full w-full absolute inset-0">
      <div className="p-3 container mx-auto relative flex flex-col h-full">
        <div className="flex items-center"></div>
        <div className="mt-6">
          <Header></Header>
        </div>

        <div className="tex-center flex flex-col items-center justify-center p-6">
          <img src={OpenBox} alt="" className="w-[108px]" />

          <p className="text-2xl font-bold text-primary mt-3">
            Congratulations!
          </p>
          <p className="text-center font-bold mt-[30px]">
            WELL DONE, YOU HAVE SUCCESSFULLY STARTED THE GASHAPON MACHINE PLEASE
            CHECK YOUR PRIZE
          </p>
        </div>
        <div className="flex items-center justify-center">
          <QRCode size={100} value={account.address}></QRCode>
        </div>
        <div className="text-center mt-[10px] font-thin px-[24px]">
          If your prize consists of digital assets, kindly contact the organizer
          using the provided QR code.
        </div>

        {/* <div className="p-3">
          <p className="text-center font-bold uppercase mb-3">CONTENTS</p>

          <div className="bg-slate-50 rounded-lg shadow-lg divide-y">
            <div className="flex font-bold items-center p-3">
              <div className="w-12 h-12 rounded overflow-hidden mr-3 flex items-center justify-center bg-slate-900">
                {SupportedAssets[contract] ? (
                  <img
                    className="w-full h-full object-cover"
                    src={SupportedAssets[contract].icon_url}
                  />
                ) : null}
              </div>
              <div>
                <p className="leading-none">
                  {SupportedAssets[contract].symbol}
                </p>
                <p className="text-xs">{SupportedAssets[contract].name}</p>
              </div>
              <p className="ml-auto">
                {protocol === "nep17" ? (
                  <span>
                    {quantity /
                      Math.pow(10, SupportedAssets[contract].decimals)}
                  </span>
                ) : (
                  <span></span>
                )}
              </p>
            </div>
          </div>
        </div> */}
        <div className="mt-auto w-full p-3">
          <button
            onClick={(e) => {
              viewWallet();
            }}
            className="bg-primary w-full py-2 font-semibold text-white uppercase"
          >
            VIEW YOUR PRIZE WALLET
          </button>
        </div>
      </div>
      {/* {isGoldKey ? (
        <div className="absolute w-full bottom-0 left-0 h-5/6 bg-white rounded-t-xl overflow-hidden flex flex-col justify-center drop-shadow-[0_-9px_17px_rgba(0,0,0,0.25)]">
          <p className="bg-yellow-400 py-3 text-center text-sm font-bold">
            YOU'VE WON THE GOLD KEY
          </p>
          <div className=" flex flex-col items-center justify-center w-full h-full ">
            <div className="bg-slate-800 w-32 h-32 rounded-lg drop-shadow-lg flex items-center justify-center">
              <svg
                width="82"
                height="91"
                viewBox="0 0 82 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.27991 11.811L22.3287 26.8598C24.273 28.8041 26.9101 29.8964 29.6597 29.8964H53.4415C56.1911 29.8964 58.8282 28.8041 60.7725 26.8598L75.8213 11.811"
                  stroke="#F9DE02"
                  strokeWidth="5.19765"
                  strokeLinecap="square"
                />
                <path
                  d="M49.9323 38.3384V48.242C49.9323 52.5911 51.2997 56.83 53.8412 60.3592L69.5584 82.1844"
                  stroke="#F9DE02"
                  strokeWidth="5.19765"
                  strokeLinecap="square"
                />
                <path
                  d="M33.2245 38.3384V48.242C33.2245 52.5911 31.857 56.83 29.3155 60.3592L13.5984 82.1844"
                  stroke="#F9DE02"
                  strokeWidth="5.19765"
                  strokeLinecap="square"
                />
                <path
                  d="M11.7817 29.8964H3.39453"
                  stroke="#F9DE02"
                  strokeWidth="5.19765"
                  strokeLinecap="square"
                />
                <path
                  d="M79.0001 29.8964H70.6129"
                  stroke="#F9DE02"
                  strokeWidth="5.19765"
                  strokeLinecap="square"
                />
                <path
                  d="M34.6939 72.6423V87.6106"
                  stroke="#F9DE02"
                  strokeWidth="5.19765"
                  strokeLinecap="square"
                />
                <path
                  d="M47.411 72.6423V87.6106"
                  stroke="#F9DE02"
                  strokeWidth="5.19765"
                  strokeLinecap="square"
                />
                <circle
                  cx="41.4515"
                  cy="11.1507"
                  r="8.30143"
                  stroke="#F9DE02"
                  strokeWidth="5.19765"
                  strokeLinecap="square"
                />
              </svg>
            </div>
            <p className="font-bold text-2xl mt-6 text-yellow-400">
              Congratulations!
            </p>
            <p className="font-bold mt-1">YOU’RE A SPECIAL GOLD KEY WINNER!</p>
            <p className="mt-3 text-center p-6">
              Try and see if your Gold Puppet Key has what it takes to unlock
              the chest.
            </p>
          </div>
          <div className="mt-auto w-full p-3">
            <button
              onClick={viewWalletAndOpenScan}
              className="bg-yellow-400 w-full text-white font-bold uppercase py-2"
            >
              SCAN GOLD CHEST
            </button>
          </div>
        </div>
      ) : null} */}
    </div>
  );
}
