import React from "react";
import AccountContext from "../../contexts/AccountContext";
import BlacksmithPuppet from "./../../assets/puppet_blacksmith.png";
import BlacksmithChest from "./../../assets/blacksmith_chest.png";
import BallBox from "./../../assets/ball_box.svg";
import Header from "../Components/Header";

export default function ConnectWalletConnect(props) {
  const { onApprove, onCancel, chestMetadata } = props;
  const { account, chestKey, puppetTokenId, goldPuppetTokenId } =
    React.useContext(AccountContext);

  const chestConfigurations = {
    green: {
      chestColor: "text-primary",
      chestKeyColor: "text-primary",
    },
    blue: {
      chestColor: "text-blue-600",
      chestKeyColor: "text-blue-600",
    },
    gold: {
      chestColor: "text-yellow-400",
      chestKeyColor: "text-yellow-400",
    },
    blacksmith: {
      chestColor: "text-black",
      chestKeyColor: "text-black",
    },
  };

  const chestColor = () => {
    var config = chestConfigurations[chestMetadata.chestColor];
    if (config) {
      return config.chestColor;
    }
    return "text-gray-600";
  };

  const chestKeyColor = () => {
    var config = chestConfigurations[chestMetadata.chestColor];
    if (config) {
      return config.chestKey;
    }
    return "text-gray-600";
  };

  return (
    <div className="p-6 text-secondary absolute inset-0 flex flex-col items-center w-full h-full bg-white">
      <div className="mt-6">
        <Header></Header>
      </div>
      <div className="py-6">
        <img src={BallBox} className="w-[200px]" />
        <div className="text-3xl text-gray-400 flex items-center justify-center scale-[2.5] mt-[30px]">
          <svg
            width="75"
            height="17"
            viewBox="0 0 75 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="13" cy="9" r="2" fill="#D0E8EB" fillOpacity="0.6" />
            <circle cx="2" cy="9" r="2" fill="#D0E8EB" fillOpacity="0.6" />
            <path
              d="M45.5 2.98C48.5451 2.98 51.02 5.45493 51.02 8.5C51.02 11.5451 48.5451 14.02 45.5 14.02H38.95V16.75H45.5C50.0539 16.75 53.75 13.0539 53.75 8.5C53.75 3.94607 50.0539 0.25 45.5 0.25H38.95V2.98H45.5ZM30.45 7.05V9.95H43.55V7.05H30.45ZM28.5 16.75H35.05V14.02H28.5C25.4549 14.02 22.98 11.5451 22.98 8.5C22.98 5.45493 25.4549 2.98 28.5 2.98H35.05V0.25H28.5C23.9461 0.25 20.25 3.94607 20.25 8.5C20.25 13.0539 23.9461 16.75 28.5 16.75Z"
              fill="#D0E8EB"
              fillOpacity="0.8"
              stroke="white"
              strokeWidth="0.5"
            />
            <circle cx="73" cy="9" r="2" fill="#D0E8EB" fillOpacity="0.6" />
            <circle cx="62" cy="9" r="2" fill="#D0E8EB" fillOpacity="0.6" />
          </svg>
        </div>
      </div>
      <div className="text-center">
        <div>
          <div>
            <p className="font-semibold text-lg mt-[50px] uppercase">
              Connect to Gashapon Machine?
            </p>
            <p className="px-6 text-center mt-[20px] font-thin">
              By clicking accept, you allow the Gashapon Machine to connect to
              your wallet.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-auto flex flex-col gap-6 items-center justify-between w-full">
        <div className="flex items-center w-full justify-center gap-12">
          <div className="text-center">
            <button
              className="bg-primary w-full py-[5px] font-semibold text-white uppercase px-[35px]"
              onClick={onApprove}
            >
              Accept
            </button>
          </div>
          <div className="text-center">
            <button
              className="bg-[#4eac93] w-full py-[5px] font-semibold text-white uppercase px-[35px]"
              onClick={onCancel}
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
