import React, { useState } from "react";
import { Link } from "react-router-dom";
import AccountContext from "../../contexts/AccountContext";
import Header from "../Components/Header";
import WithdrawAssets from "../Components/WithdrawAssets";
import ViewPrivateKey from "../Components/ViewPrivateKey";
import Global from "../../Global";
import Neon, { rpc, api, sc } from "@cityofzion/neon-js";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import WalletConnectContext from "../../contexts/WalletConnectContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import EventContext from "../../contexts/EventContext";
import { useWalletConnect } from "../../contexts/WalletConnectContext";
import * as ChestProps from "@cityofzion/props";
import QRCode from "react-qr-code";
import BigNumber from "bignumber.js";

export default function Home(props) {
  const SupportedAssets = Global.Assets;

  const { sessions } = props;
  const navigate = useNavigate();
  const [holdBalance, setHoldBalance] = useState([]);
  const [isWithdrawAssetsPresented, setIsWithdrawAssetsPresented] =
    React.useState(false);
  const [isViewPrivateKeyPresented, setIsViewPrivateKeyPresented] =
    React.useState(false);
  const {
    account,
    chestKey,
    isEligible,
    puppetTokenId,
    nep11Balances,
    balances,
  } = React.useContext(AccountContext);
  // const { wcClient, isConnected, session, proposal, chestMetadata } = React.useContext(WalletConnectContext)
  const { event } = React.useContext(EventContext);
  const walletConnectCtx = useWalletConnect();

  React.useEffect(() => {
    if (account !== null) {
      loadAssets();
    }
  }, [account]);

  const loadAssets = async () => {
    fetch("https://neofura.ngd.network", {
      method: "POST",
      body: JSON.stringify({
        params: { Address: account.address },
        method: "GetAssetsHeldByAddress",
        jsonrpc: "2.0",
        id: 1,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setHoldBalance(res.result.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDismissWithDrawAssets = () => {
    setIsWithdrawAssetsPresented(false);
  };

  const onDismissViewPrivateKey = () => {
    setIsViewPrivateKeyPresented(false);
  };

  return (
    <div className="text-secondary overflow-auto pb-[80px] pt-[10px] px-[10px]">
      {isWithdrawAssetsPresented ? (
        <WithdrawAssets
          isOpen={isWithdrawAssetsPresented}
          onDismiss={onDismissWithDrawAssets}
        ></WithdrawAssets>
      ) : null}

      {isViewPrivateKeyPresented ? (
        <Transition show={isViewPrivateKeyPresented} as={Fragment}>
          <Dialog onClose={() => onDismissViewPrivateKey()}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/30" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel>
                <ViewPrivateKey
                  isOpen={isViewPrivateKeyPresented}
                  onDismiss={onDismissViewPrivateKey}
                />
              </Dialog.Panel>
            </Transition.Child>
          </Dialog>
        </Transition>
      ) : null}
      <div className="flex items-center gap-4">
        <button
          onClick={(e) => {
            setIsViewPrivateKeyPresented(!isViewPrivateKeyPresented);
          }}
          className="text-sm flex items-center gap-2 py-1 px-3 text-white rounded-full font-bold uppercase bg-secondary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-4 h-4"
          >
            <path
              fillRule="evenodd"
              d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z"
              clipRule="evenodd"
            />
          </svg>

          <span>Export</span>
        </button>
        <div className="ml-auto">
          <button
            onClick={(e) => {
              setIsWithdrawAssetsPresented(!isWithdrawAssetsPresented);
            }}
            className="text-sm flex items-center p-1 px-3 text-white rounded-full font-bold uppercase bg-primary"
          >
            <svg
              className="mr-2"
              width="12"
              height="15"
              viewBox="0 0 12 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 5.29412H8.57143V0H3.42857V5.29412H0L6 11.4706L12 5.29412ZM0 13.2353V15H12V13.2353H0Z"
                fill="white"
              />
            </svg>
            <span>Withdraw</span>
          </button>
        </div>
      </div>
      <div className="p-3 container mx-auto relative flex flex-col items-center justify-center">
        <div className="mt-6">
          <Header></Header>
        </div>
        <div className="mx-auto shadow-xl rounded-xl p-6 mt-6 mb-6">
          <svg
            className="w-20 h-20"
            viewBox="0 0 106 117"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 20.5092V98.7639L50.922 117V38.0963L105.981 17.8556L56.0136 0L0 20.5092Z"
              fill="#00E599"
            />
            <path
              d="M55.0651 40.347V83.1558L106 101.36L105.978 21.5642L55.0651 40.347Z"
              fill="#00AF92"
            />
          </svg>
        </div>
        {account === null ? null : (
          <div className="font-bold text-sm text-center p-2 px-4 bg-gray-100 rounded-full">
            {account.address}
          </div>
        )}
        {account === null ? (
          <div className="p-6">
            <p>Get paper wallet at NEO Booth</p>
            {event.prize_description}
          </div>
        ) : null}

        {account === null ? null : (
          <div className="flex items-center justify-center mt-[20px]">
            <QRCode size={150} value={account.address}></QRCode>
          </div>
        )}
        <p className="font-semibold my-[10px] uppercase">ASSETS</p>
        {account === null
          ? null
          : holdBalance &&
            holdBalance.map((v, i) => {
              if (SupportedAssets[v.asset]) {
                return (
                  <div
                    key={i}
                    className="flex items-center justify-start rounded-lg w-full overflow-hidden shadow-xl mb-[10px]"
                  >
                    <div className="w-16 h-16 bg-slate-900 flex items-center justify-center">
                      {SupportedAssets[v.asset] ? (
                        <img src={SupportedAssets[v.asset].icon_url} />
                      ) : null}
                    </div>
                    <div className="pl-3">
                      <p className="font-bold leading-none">
                        {SupportedAssets[v.asset]?.symbol}
                      </p>
                      <p>
                        {new BigNumber(v.balance)
                          .shiftedBy(
                            0 - (SupportedAssets[v.asset]?.decimals ?? 0)
                          )
                          .toFormat()}
                      </p>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}

        {account === null ? (
          <div className=""></div>
        ) : (
          <div className="mt-auto p-3 w-full fixed bottom-0 left-0 right-0 bg-white">
            {walletConnectCtx.initialized === true ? (
              <Link to={"/asset"}>
                <button className="bg-primary w-full py-2 font-semibold text-white uppercase">
                  Next
                </button>
              </Link>
            ) : balances && balances.length === 0 ? null : (
              <div className="text-center">
                <p>Loading...</p>
                <p className="text-xs">Please wait a moment</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
